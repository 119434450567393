// used to prevent misspellings and scale changes to app wide history.push('xxxxx')
export const APP_ROUTES = {
	//LANDING: '/',

	// marketing routes
	ROOT: '/',
	SERVICES: '/services',
	KITTING: '/kitting',
	MDM: '/mdm',
	ABOUT_US: '/about-dspcell',
	WHY_US: '/why-dspcell',
	CONTACT: '/contact',

	ADMIN_MAIN: '/admin/',
	ADMIN_MANIFEST: '/manifest',

	ADMIN_PRODUCT_MANIFEST_DETAILS: '/product-manifest-details/:productId', // shows the product with the manifest options
	ADMIN_MANIFEST_DETAILS_UPSERT: '/manifest-details/:productId/:manifestId', // shows the product with the manifest options

	// admin products
	ADMIN_PRODUCTS: '/products',
	ADMIN_SINGLE_PRODUCT: '/single-product/:productId',


	LOGIN: '/login',
	REGISTER: '/register',
	LOGOUT: '/logout',



	BUY_PHONES: '/buy/phones/',
	BUY_ACCESSORIES: '/buy/accessories/',
	BUY_ITEM_DETAILS: '/buy/item-details/',
	BUY_ACCESSORY_DETAILS: '/buy/accessories/accessory-details',


	CART: '/cart/',
	ORDER_RESULTS: '/results/order-results'


	// account

};


