import React from 'react';
import { Route } from 'react-router-dom';
import { APP_ROUTES } from './app_nav_consts';

// auth
//const Confirm = React.lazy(() => import('../pages/account/Confirm'));
//const UpdatePassword = React.lazy(() => import('../pages/landing/UpdatePassword'));


// dashboard

// apps
const ErrorPageNotFound = React.lazy(() => import('../pages/error/PageNotFound'));
const ServerError = React.lazy(() => import('../pages/error/ServerError'));
// - other
//const Maintenance = React.lazy(() => import('../pages/other/Maintenance'));
//const DspOrders = React.lazy(() => import('../pages/landing/dsp-orders/'));
//const PreCheckout = React.lazy(() => import('../pages/landing/dsp-orders/PreCheckout'));



// new endpoints
// landing
const Root = React.lazy(() => import('../app/root/'));
const Services = React.lazy(() => import('../app/solutions/services/'));
const Kitting = React.lazy(() => import('../app/solutions/kitting/'));
const Mdm = React.lazy(() => import('../app/solutions/mdm/'));
const WhyUs = React.lazy(() => import('../app/why/'));
const AboutUs = React.lazy(() => import('../app/about/'));
const Contact = React.lazy(() => import('../app/contact/'));



// auth routes
//const Login = React.lazy(() => import('../external/auth/login'));
const Login = React.lazy(() => import('../app/auth/login/'));
const Register = React.lazy(() => import('../app/auth/register/'));


// admin routes
const AdminMain = React.lazy(() => import('../app/admin/'));
const AdminManifest = React.lazy(() => import('../app/admin/manifest/'));
const AdminProductManifestDetails = React.lazy(() => import('../app/admin/manifest/product-manifest-details'));
const AdminManifestDetails = React.lazy(() => import('../app/admin/manifest/manifest-details'));


// admin products
const AdminProducts = React.lazy(() => import('../app/admin/products/products'));
const AdminSingleProduct = React.lazy(() => import('../app/admin/products/single-product'));



// buy routes
const BuyPhones = React.lazy(() => import('../app/buy/phones/'));
const ItemDetails = React.lazy(() => import('../app/buy/itemDetails/'));
const AccessoryDetails = React.lazy(() => import('../app/buy/accessories/buy-accessory'));

// cart
const Cart = React.lazy(() => import('../app/cart/'));
const OrderResults = React.lazy(() => import('../app/results/order-results'));





// root routes
const rootRoute = {
    path: APP_ROUTES.ROOT,
    exact: true,
    component: Root, // root domain doman.com/
    //component: () => <Redirect to="/" />,
    route: Route,
};

const otherPublicRoutes = [
    {
        path: APP_ROUTES.SERVICES,
        name: 'solutions',
        component: Services,
        route: Route,
    },
    {
        path: APP_ROUTES.KITTING,
        name: 'kitting',
        component: Kitting,
        route: Route,
    },

    {
        path: APP_ROUTES.MDM,
        name: 'mdm',
        component: Mdm,
        route: Route,
    },


    {
        path: APP_ROUTES.WHY_US,
        name: 'why-dspcell',
        component: WhyUs,
        route: Route,
    },
    {
        path: APP_ROUTES.ABOUT_US,
        name: 'about-dspcell',
        component: AboutUs,
        route: Route,
    },
    {
        path: APP_ROUTES.CONTACT,
        name: 'contact',
        component: Contact,
        route: Route,
    },



    {
        path: '/login',
        name: 'login',
        component: Login,
        route: Route,
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        route: Route,
    },



    {
        path: '/error-404',
        name: 'Error - 404',
        component: ErrorPageNotFound,
        route: Route,
    },
    {
        path: '/error-500',
        name: 'Error - 500',
        component: ServerError,
        route: Route,
    },



    {
        path: '*',
        name: '/',
        component: Root,
        route: Route,
    },
];



const adminRoutes = [
    {
        path: APP_ROUTES.ADMIN_MAIN,
        name: 'admin',
        component: AdminMain,
        route: Route,
    },

    {
        path: APP_ROUTES.ADMIN_MANIFEST,
        name: 'manifest',
        component: AdminManifest,
        route: Route,
    },
    {
        path: APP_ROUTES.ADMIN_PRODUCT_MANIFEST_DETAILS,
        name: 'product-manifest-details',
        component: AdminProductManifestDetails,
        route: Route,
    },
    {
        path: APP_ROUTES.ADMIN_MANIFEST_DETAILS_UPSERT,
        name: 'manifest-details',
        component: AdminManifestDetails,
        route: Route,
    },
    {
        path: APP_ROUTES.ADMIN_PRODUCTS,
        name: 'products',
        component: AdminProducts,
        route: Route,
    },
    {
        path: APP_ROUTES.ADMIN_SINGLE_PRODUCT,
        name: 'singleProduct',
        component: AdminSingleProduct,
        route: Route,
    },

];



const buyRoutes = [
    {
        path: APP_ROUTES.BUY_PHONES,
        name: 'buy-phones',
        component: BuyPhones,
        route: Route,
    },
    {
        path: APP_ROUTES.BUY_ITEM_DETAILS,
        name: 'buy-item-details',
        component: ItemDetails,
        route: Route,
    },
    {
        path: APP_ROUTES.BUY_ACCESSORY_DETAILS,
        name: 'buy-accessory-details',
        component: AccessoryDetails,
        route: Route,
    },
    // {
    //     path: APP_ROUTES.BUY_ITEM_DETAILS,
    //     name: 'buy-phones',
    //     component: BuyPhones,
    //     route: Route,
    // },


];


const cartRoutes = [
    {
        path: APP_ROUTES.CART,
        name: 'cart',
        component: Cart,
        route: Route,
    },
    {
        path: APP_ROUTES.ORDER_RESULTS,
        name: 'cart/order-results',
        component: OrderResults,
        route: Route,
    },
];



// flatten the list of all nested routes
const flattenRoutes = (routes) => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach((item) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
//const authProtectedRoutes = [...appRoutes];
const publicRoutes = [rootRoute, ...adminRoutes, ...buyRoutes, ...cartRoutes, ...otherPublicRoutes];

//const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);

export { publicRoutes, publicProtectedFlattenRoutes };
