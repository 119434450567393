export const VERSION = '1.0.0.109';
export const API_VERSION = '1.0.0.104';
export const COPYRIGHT = new Date().getFullYear() + '© Wireless Business Consultants';
export const ENDPOINTS = {
	// local dev
	//API: 'http://localhost:5063/api',
	//CALL_BACK_URL: 'http://localhost:3001/update-password'

	// dev
	//API: 'https://wwd-api-dev-gjawczbfcmbbe2gf.eastus2-01.azurewebsites.net/api',
	CALL_BACK_URL: 'https://wirelesswarehousedirect.com//update-password',



	// prod
	API: 'https://wwd-api-prod.azurewebsites.net/api',
	//CALL_BACK_URL: 'https://prodapi.caa-apps.com/reset-password'
};


// device, accessories, mobile broadbad & routers, tablets


export const RECAPTCHA = '6LfQjqsoAAAAALJ4f7oZEH-beptTqoQkkzoY2wqb';


export const DOMAIN = {
	ID: '48cac62e-36e0-4a19-89b3-c329f781c7ba',
	VALUE: 'dspcell.com'
}



export const BrandColors = {
	secondary: '#FFDC00',
	primary: '#005FAA'
}


//export const SITE = 'dsp-wireless.com';

export const API_METHOD = {
	POST: 'POST',
	GET: 'GET',
	DELETE: 'DELETE'
};

// export const LOCAL_STORAGE = {
// 	PROFILE: 'dspcell-profile',
// 	TOKEN: 'dspcell-token',
// 	CART: 'dspcell-cart',
// 	PRODUCT_ID: 'dspcell-productId'
// };

export const SESSION_STORAGE = {
	PROFILE: 'dspcell-profile',
	TOKEN: 'dspcell-token',
	CART: 'dspcell-cart',
	PRODUCT_ID: 'dspcell-productId',
	SELECTED_OPTIONS: 'dspcell-selectedOptions',
	CALLBACK_URL: 'dspcell-callBackUrl',
};
export const STATUS = {
	ACTIVE: { ID: 1, VALUE: 'Active' },
	INACTIVE: { ID: 0, VALUE: 'Inactive' },
};

// purchase type
export const PURCHASE_TYPES = [
	{
		value: "baa4f551-5048-476e-8850-a0f1e90692db",
		label: "Buy"
	},
	{
		value: "40940fe0-bdfc-44ba-8d04-4e9a26134dd5",
		label: "No Contract"
	},
	{
		value: "564e5818-c58d-4a8f-8042-2f150243bc0c",
		label: "Subsidy"
	},

	{
		value: "d3776ea9-60d1-47aa-9966-3c2c1ed7c80c",
		label: "Finance"
	}
]

export const PRODUCT_TYPES = [
	// {
	// 	value: "0fbda569-6e8a-4ed8-99a7-2a01034549d4",
	// 	label: "Tablet",
	// 	isActive: "0"
	// },
	{
		value: "4e0052d1-de16-469e-b3dc-86baf5ceab03",
		label: "Phone",
		isActive: "1"
	},
	// {
	// 	value: "6d4cd8ed-90b0-404e-b8eb-70fa8d7f54ce",
	// 	label: "Service",
	// 	isActive: "1"
	// },
	{
		value: "d698235c-b4bb-4705-96cc-840027ba58b6",
		label: "Accessory",
		isActive: "1"
	}
]


export const PRODUCT_TYPE = {
	PHONE: '4e0052d1-de16-469e-b3dc-86baf5ceab03',
	TABLET: '0fbda569-6e8a-4ed8-99a7-2a01034549d4',
	ACCESSORY: 'd698235c-b4bb-4705-96cc-840027ba58b6',
	SERVICE: '6d4cd8ed-90b0-404e-b8eb-70fa8d7f54ce'
};



// installed app options
export const INSTALLED_APPS = ["Flex", "Chime", "Ementor", "Easel", "Presto", "Damage iD", "WhatsApp", "dspworkplace"];

export const CARRIERS = [

	{ value: "50a6f2cf-823a-465e-adc6-06b3202289a3", label: 'AT&T' },
	{ value: "01fcf663-bad3-4db8-8d53-b833b8bba364", label: 'Verizon' },
	{ value: "a7327e38-15fd-4f10-b407-f598250add43", label: 'US Cellular' },
	{ value: "d9ac1567-efc6-4b86-ab9f-85d868986cb6", label: 'T-Mobile' },
];

export const STATUSES = [

	{ value: 1, label: 'Active' },
	{ value: 0, label: 'Inactive' },
	{ value: 2, label: 'Archived' }
];