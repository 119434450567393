import { API_METHOD, ENDPOINTS, DOMAIN } from '../appConsts';
import { handleFetch } from './handleFetch';
const apiRoot = ENDPOINTS.API + '/product';


const productInit = {
    "productId": "",
    "productCategory": "",
    "warranty": "",
    "processor": "",
    "description": "",
    "usb": "",
    "memory": "",
    "included": "",
    "productName": "",
    "durability": "",
    "productCode": "",
    "isDSPItem": "tue",
    "battery": "",
    "manufacturer": "",
    "carrier": "",
    "unitPrice": "",
    "display": "",
    "bannerMessage": "",
    "showBanner": false,
    "image_1": "",
    "image_2": "",
    "images": [],
    "quantity": "",
    "ratePlanId": "",
    "purchaseTypeId": "",
    "financeTerms": [],
    "financeTermsId": "",
    "showDeviceBanner": false,
    "deviceBannerMessage": "",
    "installedAppString": ""
};

export { productInit };

export const getAllAdmin = () => {

    // for admin - return all products
    let url = `${apiRoot}/GetAllAdmin`;

    const options = {
        method: API_METHOD.GET
    };
    return handleFetch(url, options, true);
};


export const getSingle = (productId, getAll) => {
    // should only return active products
    let url = `${apiRoot}/GetSingle/${productId}/${getAll}`;

    const options = {
        method: API_METHOD.GET
    };
    return handleFetch(url, options, true);
};

export const upsert = (data) => {

    let url = `${apiRoot}/Upsert`;

    const options = {
        method: API_METHOD.POST,
        body: JSON.stringify(data)
    };
    return handleFetch(url, options, true);
};
